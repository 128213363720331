  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/1-6-1-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li><a href='/expertise-construction/assistance-reception-immobilier/'>Assistance réception bien immobilier ou travaux</a></li><li>Réception CCMI</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Le contrat de construction de maison individuelle</h1>

<p>Le CCMI ou contrat de construction de maison individuelle est un type de contrat permettant d’assurer certaines garanties au futur acquéreur Maître d’Ouvrage. Il est de plus en plus souscrit par de futurs propriétaires. </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Réception CCMI" />
</div>


<h2>Pourquoi être accompagné lors d’une vente d’un bien CCMI ? </h2>

<p>Un chantier compliqué ? des travaux qui n’avancent pas ? </p>
<p>Nos experts vous accompagnent durant les phases de construction de votre ouvrage en CCMI.</p>

<ul><li>Avant la réception :</li></ul>

<p>ll est possible que lors des différentes phases de chantier, vous vous apercevez ou non de défauts de construction (fondations, ancrage, absence d’armatures non prévue..) ou simplement, vous désirez être accompagné d’un Expert du Bâtiment afin d’éviter d’éventuels “laisser aller” de la part de l’Entrepreneur. </p>

<p>L’Expert construction vous assiste alors dans toutes les phases de travaux et vous informe de la bonne ou mauvaise réalisation des ouvrages et de leur avancement.</p>

<p>Il répond à vos questions et vous oriente sur les recours possibles en cas de malfaçons graves constatées.</p>

<ul><li>Lors de la réception : </li></ul>

<p>La réception de l’ouvrage CCMI marque la fin du chantier. Il est temps de la remise des clés. Si défauts constatés, le Maître d’Ouvrage a cependant la possibilité d'émettre une liste des réserves. </p>
<p>Pour ne pas commettre d’erreurs lors de la réception de sa maison CCMI, il est vivement recommandé d’être assisté par un Expert Bâtiment certifié.</p>

<p>L’Expert construction vous accompagne lors de la réception de votre ouvrage afin :</p>

<ul><li>d’établir la liste des réserves dans le cadre du Procès Verbal de réception,</li>
<li>de vérifier la conformité des engagements contractuels,</li>
<li>de vérifier d’éventuelles malfaçons ou autres désordres,</li>
<li>d’éviter les coups de pression éventuels du constructeur pour la remise des  clés.</li></ul>
<p> </p>
<h2>Nos services d'accompagnement réception de maison CCMI </h2>

<p>Notre Expert CCMI vous accompagne et vous évite toute complication future. </p>

<p>Nous effectuons :</p>
<ul><li>des visites techniques in situ,</li>
<li>nous relevons les désordres et malfaçons,</li>
<li>nous vérifions la conformité des plans d'exécution,</li>
<li>nous vérifions la conformité de la notice descriptive des matériaux engagés,</li>
<li>vous accompagnons pour la rédaction des réserves.</li></ul>

<p>La phase de réception est l’étape la plus délicate de l’achat de toute une vie. </p>

<p>Se faire accompagner d’un Expert vous permet de répondre à vos questions, lever vos doutes afin d’éviter toutes complications futures ! </p>

<p>Vous serez enfin assuré d’avoir un ouvrage conforme à vos attentes, structurellement fiable afin de pouvoir l’occuper sereinement et en toute quiétude. Au revoir les mauvaises surprises.</p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Demander un devis</a>
    </div>
</div>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/assistance-reception-immobilier/ccmi/' className='active'>Réception CCMI</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details